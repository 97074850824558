import {gql} from '@apollo/client'

import {CONTACT_FRAGMENT, UPLOAD_FRAGMENT, USER_PROGRAM_FRAGMENT} from '../fragments/userData'

export const CONTACTS = gql`
  ${CONTACT_FRAGMENT}
  query contacts($everplanId: ID) {
    contacts(everplanId: $everplanId) {...ContactFragment}
  }
`

export const SCORE = gql`
  query score($userId: ID) {
    score(userId: $userId) {
      id
      total
      enabled
    }
  }
`
export const USER_PROGRAMS = gql`
  ${USER_PROGRAM_FRAGMENT}
  query userPrograms {
    userPrograms {...UserProgramFragment}
  }
`

export const DOWNLOAD_PRESIGNER = gql`
  query downloadPresigner($uploadId: String!) {
    downloadPresigner(uploadId: $uploadId) {
      id
      everplanId
      filename
      presignedUrl
    }
  }
`

export const UPLOAD = gql`
  ${UPLOAD_FRAGMENT}
  query upload($id: String!) {
    upload(id: $id) {
      ...NewUploadFragment
    }
  }
`
